





























import { Component } from "vue-property-decorator";
import UsersList from "@widgets/user/UsersList.vue";
import { Routes } from "@/types/core";
import { BaseView } from "@/core/UI/Views/BaseView";
import { User } from "@/generated/graphql";
@Component({
  components: {
    UsersList,
  },
})
export default class extends BaseView {
  private readonly Routes: typeof Routes = Routes;
  protected selected: User[] = [];

  get viewUserList(): boolean {
    return this.$store.getters["session/isAllow"]("READ_USERS_LIST");
  }
  public created(): void {
    if (!this.viewUserList) this.$router.push({ name: Routes.noRights });
  }
}
